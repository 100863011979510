<template>
  <button class="btn"
  :class="{ 'btn-primary': isOn, 'btn-secondary': !isOn}"
  @click="$emit('click')"
  v-html="isOn ? `<strong>${labels[1]}</strong>` : labels[0]"></button>
</template>
<script>
  export default {
    props: {
      isOn: Boolean,
      labels: {
        type: Array,
        default: () => ['Off', 'On'],
        validator(value) {
          return value.length === 2
        }
      }
    },
  }
</script>
