<template>
  <div class="config container">
    <div class="mb-3">
      <h3>{{ $t('label.config') }}</h3>

      <div class="row p-2">
        <div class="col-sm-9">
          <div><b>{{ $t('label.isCheckinReassign') }}</b></div>
          <div><small>{{ $t('message.isCheckinReassign') }}</small></div>
        </div>
        <div class="col-sm-3">
          <toggle-button :labels="[$t('label.off'), $t('label.on')]" :isOn="isCheckinReassign" @click="toggle('isCheckinReassign')"></toggle-button>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-sm-9">
          <div><b>{{ $t('label.skipRoundAdvance') }}</b></div>
          <div><small>{{ $t('message.skipRoundAdvance') }}</small></div>
        </div>
        <div class="col-sm-3">
          <toggle-button :labels="[$t('label.off'), $t('label.on')]" :isOn="skipRoundAdvance" @click="toggle('skipRoundAdvance')"></toggle-button>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-sm-9">
          <div><b>{{ $t('label.skipRoundOverSafety') }}</b></div>
          <div><small>{{ $t('message.skipRoundOverSafety') }}</small></div>
        </div>
        <div class="col-sm-3">
          <toggle-button :labels="[$t('label.off'), $t('label.on')]" :isOn="skipRoundOverSafety" @click="toggle('skipRoundOverSafety')"></toggle-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ToggleButton from '@/components/ToggleButton.vue'
import { mapState } from 'vuex'

export default {
  name: "Config",
  components: {
    ToggleButton
  },
  computed: {
    ...mapState({
      isCheckinReassign: state => state.config.isCheckinReassign,
      skipRoundOverSafety: state => state.config.skipRoundOverSafety,
      skipRoundAdvance: state => state.config.skipRoundAdvance,
    }),
    config() {
      return {
        isCheckinReassign: this.isCheckinReassign,
        skipRoundOverSafety: this.skipRoundOverSafety,
        skipRoundAdvance: this.skipRoundAdvance
      }
    },
  },
  methods: {
    toggle(prop) {
      this.$store.dispatch('setConfig', { prop, value: !this.config[prop] })
    },
  },
}
</script>
<style scoped>

</style>
<i18n>
{
  "en": {
    "label": {
      "isCheckinReassign": "Check-in Reassign",
      "skipRoundAdvance": "Skip confirm of round advance",
      "skipRoundOverSafety": "Skip confirm of round over"
    },
    "message": {
      "isCheckinReassign": "Reassign Check-in No. when removing a player.",
      "skipRoundAdvance": "Skip confirming round advance.",
      "skipRoundOverSafety": "Skip confirming round over if all games in round are finished."
    }
  },
  "ja": {
    "label": {
      "isCheckinReassign": "チェックイン番号振り直し",
      "skipRoundAdvance": "試合開始の確認省略",
      "skipRoundOverSafety": "試合終了の確認省略"
    },
    "message": {
      "isCheckinReassign": "選手を削除した際にチェックイン番号を振り直します。",
      "skipRoundAdvance": "試合開始確認を省略します。",
      "skipRoundOverSafety": "全試合が終了したときの試合終了確認を省略します。"
    }
  }
}
</i18n>
